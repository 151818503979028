import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The 2020 - 2021 school year marked another busy and successful year in developing the Ukraine Externum Programme with our local students.`}</p>
    <p>{`LLC Pechersk International School holds a licence to offer general secondary education in Ukraine. Due to this licence, the school is able to independently conduct examinations based on the secondary school programme and issue a nationally recognised education certificate (diplomas and certificates) in addition to the Bachelor’s degree in the territory of Ukraine .`}</p>
    <p>{`The programme of the Ukrainian school represents an extra education programme based on the national standards of Ukraine for the LLC Pechersk International School students studying the Ukrainian language or willing to get an education certificate in Ukraine.`}</p>
    <p>{`The students will study a few additional subjects, such as:`}</p>
    <ul>
      <li parentName="ul">{`Ukrainian language`}</li>
      <li parentName="ul">{`Ukrainian literature`}</li>
      <li parentName="ul">{`History of Ukraine`}</li>
      <li parentName="ul">{`Geography of Ukraine`}</li>
      <li parentName="ul">{`Science of law`}</li>
    </ul>
    <p>{`The Ukrainian school has passed the state certification and received the certification attestation: the school is recognised as certified.`}</p>
    <p>{`This year, the Ukrainian Externum Programme has successfully completed the educational experiment “Teaching and methodological support of teaching International Baccalaureate programs in the system of Ukrainian education" and received positive feedback about its experimental activity.`}</p>
    <p>{`In March, the school sent another application to conduct an experiment ‘Organisational - pedagogical conditions of teaching the IB Programmes in accordance with National standard primary and secondary education. The experiment is temed for 2021 - 2027.`}</p>
    <p>{`On the basis of our institution, we are running research and experimental work on the topic: "Teaching and methodological support of teaching International Baccalaureate programs in the system of Ukrainian education". This is given and the status of an experimental institution of general secondary education of the all-Ukrainian level. The experiment program is approved for 2015 - 2020.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      